.chat {
    display: inline;
    position: relative;
    .chat-unread {
        &:after {
            content: '';
            background: $red;
            height:12px;
            width: 12px;
            border-radius: 100%;
            position: absolute;
            top: 4px;
            right: 4px;
            border: 1px solid #212120;
        }
        &.active:after {
            border-color: #404040;
        }
    }
    &-container {
        position: fixed;
        right:10px;
        width: 250px;
        min-height: 320px;
        max-height: calc(100vh - 300px);
        top: 84px;
        text-align: left;
        z-index: 9999;
        background: #252525C7 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 99px #0000004D;
        border-radius: 15px;
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        .chat-messages {
            flex: 1 1 auto;
            overflow-y: auto;
            padding: 12px 12px 0 12px;
            -webkit-overflow-scrolling: touch;
        }
        .chat-actions {
            flex: 0 1 auto;
            padding: 12px;
        }
        .chat-message {
            padding: 10px 5px 10px 5px;
            border-bottom: 1px solid lighten($light-grey, 7%);
            .message-header {
                display: flex;
                justify-content: space-between;
                font-size: .6875rem;
                margin-bottom: .5em;
                .message-user {
                    font-weight: bold;
                    text-transform: uppercase;
                    white-space:nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: .75rem;
                }
                .message-date {
                    white-space: nowrap;
                }
            }
            .message-text {
                font-size: 1.125rem;
            }
        }
        .chat-input {   
            textarea {
                resize: none;    
                background: #7676803D 0% 0% no-repeat padding-box;
                border-radius: 11px;
                border: none;
                color: white;
                font-size: 1rem;
                width: 100%;
                margin-bottom: 10px;
                padding: .5em;
                box-sizing: border-box;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                "Helvetica Neue", sans-serif;
            }
        }
        .chat-submit {
            .btn {
                width: 100%;
                background: $orange;
                padding: 15px;
                border-radius: 10px;
                justify-content: center;
                margin: 0;
            }
        }
    }
}

.bigger {
    .chat {
        .message-header {
            font-size: .8175rem;
        }
        .message-text {
            font-size: 1.25rem;
        }
    }
}

.biggest {
    .chat {
        .message-header {
            font-size: .875rem;
        }
        .message-text {
            font-size: 1.325rem;
        }
    }
}

.big-icon {
    font-size: 40px;
}