.circle {
  height: 61px;
  width: 61px;
  position: relative;
  background: url(pins/pin-icon-default.svg) center center no-repeat;
  top: 30px;
  &:before {
    height: 61px;
    width: 61px;
    content: "";
    background: url(pins/pin-gray.svg) center center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.weather-icon:before {
    top: -8px;
    left: -10px;
  }
  &.north:before {
    transform: rotate(0deg);
  }
  &.north-north-east:before {
    transform: rotate(22.5deg);
  }
  &.north-east:before {
    transform: rotate(45deg);
  }
  &.east-north-east:before {
    transform: rotate(67.5deg);
  }
  &.east:before {
    transform: rotate(90deg);
  }
  &.east-south-east:before {
    transform: rotate(112.5deg);
  }
  &.south-east:before {
    transform: rotate(135deg);
  }
  &.south-south-east:before {
    transform: rotate(157.5deg);
  }
  &.south:before {
    transform: rotate(180deg);
  }
  &.south-south-west:before {
    transform: rotate(202.5deg);
  }
  &.south-west:before {
    transform: rotate(225deg);
  }
  &.west-south-west:before {
    transform: rotate(247.5deg);
  }
  &.west:before {
    transform: rotate(270deg);
  }
  &.west-north-west:before {
    transform: rotate(292.5deg);
  }
  &.north-west:before {
    transform: rotate(315deg);
  }
  &.north-north-west:before {
    transform: rotate(337.5deg);
  }

  &.pin-gray:before {
    background-image: url(pins/pin-gray.svg);
  }
  &.pin-green:before {
    background-image: url(pins/pin-green.svg);
  }
  &.pin-yellow:before {
    background-image: url(pins/pin-yellow.svg);
  }
  &.pin-red:before {
    background-image: url(pins/pin-red.svg);
  }
  &.pin-orange:before {
    background-image: url(pins/pin-orange.svg);
  }
  &.pin-white:before {
    background-image: url(pins/pin-white.svg);
  }

  &.pin-icon-default {
    background-image: url(pins/pin-icon-default.svg);
  }
  &.pin-icon-chopper {
    background-image: url(pins/pin-icon-chopper.svg);
  }
  &.pin-icon-tractor {
    background-image: url(pins/pin-icon-tractor.svg);
  }
  &.pin-icon-semi {
    background-image: url(pins/pin-icon-semi.svg);
  }
  &.pin-icon-straight-truck {
    background-image: url(pins/pin-icon-straight-truck.svg);
  }
  &.pin-icon-mower {
    background-image: url(pins/pin-icon-mower.svg);
  }
  &.pin-icon-tractor-wagon {
    background-image: url(pins/pin-icon-tractor-wagon.svg);
  }
  &.pin-icon-merger {
    background-image: url(pins/pin-icon-merger.svg);
  }
  &.pin-icon-push-tractor {
    background-image: url(pins/pin-icon-push-tractor.svg);
  }
  &.pin-icon-x-wing {
    background-image: url(pins/pin-icon-x-wing.svg);
  }
  &.pin-icon-you {
    background-image: url(pins/pin-icon-you.svg);
  }
}

// .circle {
//   width: 50px;
//   height: 50px;
//   border: 2px solid black;
//   border-radius: 50%;
//   position: relative;
// }
//
// .circle::before {
//   background-color: lime;
//   background-image: url("https://i.ibb.co/MC9g43y/truck.png");
//   background-size: 40px;
//   background-repeat: no-repeat;
//   background-position: center center;
//   padding: 5px;
//   width: 40px;
//   height: 40px;
//   display: block;
//   position: absolute;
//   content: "";
//   border-radius: 50%;
// }
//
// .circle::after {
//   position: absolute;
//   bottom: 0;
//   content: "";
//   width: 0;
//   height: 0;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   border-bottom: 12px solid black;
// }
//
// .circle-2::before {
//   background-color: red;
//   background-image: url("https://i.ibb.co/MC9g43y/truck.png");
//   background-size: 40px;
//   background-repeat: no-repeat;
//   background-position: center center;
//   padding: 5px;
//   width: 40px;
//   height: 40px;
//   display: block;
//   position: absolute;
//   content: "";
//   border-radius: 50%;
// }
