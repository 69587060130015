.assignment-card {
  float: left;
  background-color: rgb(53, 53, 53);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 32%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  border-radius: 6px;
  min-height: 103px;  // this is to account for the new day shift/night shift change that can result in cards of different heights

  &.confirmed {
    background-color: #016b1c;
  }
}

.assignment-card-info {
  .crew-member-name {
    font-size: 25px;
    font-weight: bold;
    color: orange;
  }

  .equipment-name {
    font-size: 20px;
  }

  .confirm-check {
    float: right;
    margin-top: 5px;

    &.unconfirmed {
      color: gray;
    }
  }

  padding: 5px 16px;
}
