.profile-small-label {
  color: gray;
  font-size: 16px;
}

.profile-info {
  font-size: 20px;
  margin-bottom: 1em;
}

.profile-card {
  margin-bottom: 20px;
  background-color: #202020;
  padding: 20px;
  border-radius: 20px;
}
