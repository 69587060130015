.header-bar {
  background: #1D1D1DF0 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.33px 0px #FFFFFF26;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 28px 12px 6px 12px;
  z-index: 9999;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .left {
    padding: 3px 0;
    flex: 1 1 auto;
  }

  .center {
    flex: 1 1 auto;
    text-align: center;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow:hidden;
    padding: 0 32px;
    font-size: 1.0625;
    min-width: 0;
  }

  .right {
    padding: 3px 0;
    flex: 1 1 auto;
    text-align: right;
    justify-content: flex-end;
  }
  .left, .right {
    display: flex;
    
  }
  .btn {
    font-size: .8125rem;
    padding: 8px 10px 6px 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    box-shadow: none;
    text-transform: uppercase;
    margin: 0;
    margin: 0 8px;
    // &:first-child {
    //   margin-left: 0;
    // }
    // &:last-child {
    //   margin-right: 0;
    // }
    i {
      font-size: 1.5em;
    }
    &.active {
      background: #404040;
      border: none;
      box-shadow: none;
    }
  }
}
