.double-pane-scrollers {
	display: flex;
	align-items: stretch;
	gap: 2rem;
	position: relative;
	left:0;
	top:0;
	right:0;
	bottom: 0;
	align-items: stretch;
	height: 100%;
	.pane-1, .pane-2 {
		flex: 1;
		overflow: auto;
		padding-bottom: 6rem;
		position: relative;
	}
}