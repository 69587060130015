.right-drawer.ticket-menu {
  right: 175px;
  width: auto;
  .action-list {
    .action {
      .name {
        display: block;
        text-transform: uppercase;
        font-size: 0.875rem;
      }
    }
  }
}

.bigger {
  .right-drawer.ticket-menu {
    .action-list {
      .action {
        .name {
          font-size: 1rem;
        }
      }
    }
  }
}
.biggest {
  .right-drawer.ticket-menu {
    .action-list {
      .action {
        .name {
          font-size: 1.25rem;
        }
      }
    }
  }
}
