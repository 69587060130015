@import "alerts";
@import "colors";
@import "columns";
@import "modal";
@import "map";
@import "header-bar";
@import "footer-bar";
@import "crew-drawer";
@import "right-drawer";
@import "routes-menu";
@import "map-controls";
@import "spinner";
@import "chat";
@import "form";
@import "truck";
@import "double-pane-scrollers";
@import "my-profile";
@import "accordion";
@import "ticket-modal";
@import "assignment-card";
@import "pins/pins";

@import "ticket-menu";

@import "equipment-markers";
@import "weather-markers";
@import "field-entry-markers";
@import "error-screen";

* {
  -webkit-overflow-scrolling: touch;
}

.field-reload-modal {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}

html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.btn {
  background: #363941;
  background: -moz-linear-gradient(0deg, #363941 0%, #474a53 100%);
  background: -webkit-linear-gradient(0deg, #363941 0%, #474a53 100%);
  background: linear-gradient(0deg, #363941 0%, #474a53 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363941", endColorstr="#474a53", GradientType=1);
  padding: 0.75em 1em;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid #161a24;
  border-radius: 5px;
  font-size: 1.4375rem;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%);
  text-align: center;
  font-weight: 700;
  line-height: 1em;

  &.active {
    background: linear-gradient(0deg, #a3a9b7 0%, #757984 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a3a9b7", endColorstr="#757984", GradientType=1);
    text-shadow: 0 1px 0 rgb(0 0 0 / 35%);
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 35%), 0 1px 0 rgb(255 255 255 / 25%);
  }
}

.btn-sm {
  background: #363941;
  background: -moz-linear-gradient(0deg, #363941 0%, #474a53 100%);
  background: -webkit-linear-gradient(0deg, #363941 0%, #474a53 100%);
  background: linear-gradient(0deg, #363941 0%, #474a53 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363941", endColorstr="#474a53", GradientType=1);
  padding: 0.5em 0.5em;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid #161a24;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%);
  text-align: center;
  font-weight: 200;
  line-height: 1em;

  &.active {
    background: linear-gradient(0deg, #a3a9b7 0%, #757984 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a3a9b7", endColorstr="#757984", GradientType=1);
    text-shadow: 0 1px 0 rgb(0 0 0 / 35%);
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 35%), 0 1px 0 rgb(255 255 255 / 25%);
  }
}

.btn-group.sort {
  display: flex;

  .btn {
    flex: 1;
    font-size: 1rem;

    &.active {
      background: linear-gradient(0deg, #a3a9b7 0%, #757984 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a3a9b7", endColorstr="#757984", GradientType=1);
      text-shadow: 0 1px 0 rgb(0 0 0 / 35%);
      box-shadow: inset 0 1px 3px rgb(0 0 0 / 35%), 0 1px 0 rgb(255 255 255 / 25%);
    }
  }

  & .btn:first-child {
    border-radius: 5px 0 0 5px;
  }

  & .btn:last-child {
    border-radius: 0 5px 5px 0px;
  }
}

.orange {
  color: orange;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 auto;
}

.w-100 {
  width: 100%;
}

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.react-tabs__tab:focus:after {
  display: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.mr-3 {
  margin-right: 3em !important;
}

.ml-3 {
  margin-left: 3em !important;
}
