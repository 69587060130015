.right-drawer {
  min-width: 240px; // This is for the text of the "SHOW/HIDE FIELD ENTRY PINS" changing
  position: fixed;
  right: 10px;
  top: 80px;
  z-index: 9999;
  max-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  transition: transform 0.35s ease;
  will-change: transform;
  background: #383838c7 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 99px #0000004d;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 15px;
  &:before {
    content: "";
    height: 5px;
    width: 5px;
    border-left: 8px solid #404040;
    border-top: 8px solid #404040;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-8px) rotate(45deg);
    box-sizing: border-box;
    box-shadow: -0.5px -0.5px 0px rgba(255, 255, 255, 0.25);
  }
  .action-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    .action {
      color: #fff;
      .type-block {
        display: flex;
        align-items: center;
      }
      .name {
        white-space: nowrap;
        display: flex;
        align-items: center;
        // font-size: 1.125rem;
        font-size: 0.875rem;
        font-weight: bold;
        padding: 10px;
        i {
          width: 1.25em;
          font-size: 1.125em;
          text-align: center;
          margin-right: 0.5em;
        }
      }
    }
  }
}

.bigger {
  .right-drawer {
    .action-list {
      .name {
        font-size: 1rem;
      }
    }
  }
}

.biggest {
  .right-drawer {
    .action-list {
      .name {
        font-size: 1.25rem;
      }
    }
  }
}
