.truck-cards {
    position: fixed;
    bottom: 65px;
    display: flex;
    width: 100vw;
    .truck {
        flex: 1;
        height: 120px;
        margin: 10px 5px 10px 5px;
        overflow:hidden;
        color: white;
        font-weight: bold;
        background: #252525C7 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 99px #0000004D;
        border-radius: 10px;
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        position: relative;
        display: flex;
        flex-direction: column;
        
        &:first-child {
            margin-left: 10px;
        }
        &:last-child {
            margin-right: 10px;
        }
        .name {
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            // text-overflow: ellipsis;
        }
        .left {
            width: 50%;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;
        }
        .right {
            width: 50%;
            float: right;
            padding: 10px 10px 10px 0;
            box-sizing: border-box;
        }
        .speed {
            font-size: 1.75em;
        }
        &-mine {
            max-width: 33.33%;
            margin-left: auto !important;
            .right {
                display: flex;
                align-items: flex-end;
                flex: 0 1 auto;
                width: auto;
                .speed {
                
                    &-number {
                        font-size: 2em;
                        margin-right: 5px;
                    }
                }
            }
            .left {
                flex: 1 1 auto;
                width: auto
            }
        }
        .time {
            font-size: 1.5em;
        }
        .load-btn {
            width: 100%;
            background: $orange;
            border: none;
            color: white;
            font-size: .8125rem;
            font-weight: bold;
            padding: 12px;
            border-radius: 10px;
            margin-bottom: 4px;
            &:hover {
                cursor: pointer;
            }
        }
        .other-link {
            text-align: center;
            color: $orange;
            font-size: .8125rem;
            padding: 12px;
            &:hover {
                cursor: pointer;
            }
        }
        &-card-details {
            display: flex;
            flex: 1 0 auto;
            align-items: stretch;
        }
        &-progress {
            background:#6F7070;
            height: 18px;
            &-status {
                background: red;
                height: 100%;
            }
        }
    }
}

.bigger {
    .truck-cards {
        .truck {
            font-size: 1.125rem;
        }
    }
}

.biggest {
    .truck-cards {
        .truck {
            height: 105px;
            font-size: 1.325rem;

            .right {
                padding: 0px 10px 0px 0;
            }
        }
    }
}
