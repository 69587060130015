.pin-note {
  font-size: 20px;
}

.delete-pin {
  font-size: 15px;
}



