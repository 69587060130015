.footer-bar {
  background: #161616F0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 0px -0.5px 0px #FFFFFF29;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px;
  z-index: 9999;
  display: flex;
  align-items: center;
  height: 65px;
  box-sizing: border-box;
  border-bottom: 1px solid #212631;
  justify-content: space-around;
  .footer-item {
    display: flex;
    align-items: center;
    opacity: .45;
    font-weight: bold;
    text-transform: uppercase;
    font-size: .8125rem;
    i {
      font-size: 1.5em;
      margin-right: .5em;
    }
    &.active {
      opacity: 1;
    }
  }

  .btn {
    font-size: .9375rem;
    padding: 0.55em 1em;
    
  }
}
