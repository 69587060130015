.map-controls {
  position: fixed;
  left: 6px;
  bottom: 71px;
  z-index: 9999;
  max-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.35s ease;
  will-change: transform;
  color: white;
  padding: 6px;

  button {
    -webkit-appearance: none;
    height: 3rem;
    box-shadow: 0 0 0.5rem rgba(black, .8);
    border: none;
    border-radius: 5px;
    padding: 0;
    font-size: 1rem;
  }
}

.zoom-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  button {
    aspect-ratio: 1;
    border-radius: 50%;
  }
}

.pan-controls {
  display: grid;
  gap: 0.75rem 0.5rem;
  grid-template:
    " open1   panUp   open2" 1fr
    "panLeft  panUp  panRight" 1fr
    "panLeft panDown panRight" 1fr
    " open3  panDown  open4" 1fr
    / 1fr   1fr     1fr;

  button {
    aspect-ratio: 1;
  }
}

.pan-control {
  &.up {
    grid-area: panUp;
  }

  &.left {
    grid-area: panLeft;
  }

  &.right {
    grid-area: panRight;
  }

  &.down {
    grid-area: panDown;
  }
}
