.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #202020;
  border-radius: 20px;
}

.accordion-title:hover {
  background-color: orange;
}

.accordion-title {
  color: gray;
  font-size: 16px;
  padding: 1rem;
}
.accordion-content {
  padding: 1px;
}

.accordion-content {
  background-color: #202020;
  border-radius: 20px;
}
