// actual google maps container
#map {
    height: 100vh;
    width: 100vw;

}

// map components that overlay the "actual" map like button rows, etc.
.map {
    // bottom button row (proof of concept)
    &-bottom {
        position: absolute;
        bottom: 10px;
        left: 10px;

        button {
            margin-right: 10px;
            font-size: 1.25em;
            background: blue;
        }
    }
}
