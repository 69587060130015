.weather-icon {
  height: 40px;
  width: 40px;
  position: relative;
  background: url(pins/pin-icon-default.svg) center center no-repeat;
  top: 20px;

  // &:before {
  // height: 61px;
  // width: 61px;
  // content: "";
  // background: url(pins/pin-gray.svg) center center no-repeat;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: -1;
  // }

  background-image: url(pins/weather_icon.png);
}

body.hide-weather-pins {
  .weather-icon {
    display: none;
  }
}
