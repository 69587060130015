.ReactModal_ {
	&_Overlay {
		background: none!important;
	}
	&_Content {
		background: rgba(37,37,37,.9) 0% 0% no-repeat padding-box!important;
		box-shadow: 0px 10px 99px #0000004D!important;
		border-radius: 0!important;
		opacity: 1!important;
		backdrop-filter: blur(5px)!important;
		-webkit-backdrop-filter: blur(5px)!important;
		border: none!important;
		left: 0!important;
		right: 0!important;
		top:0!important;
		position: fixed!important;
		overflow: hidden!important;
		height: 100vh;
		box-sizing: border-box;
		// display: flex;
		// align-items: stretch;
		// width: 100%;
		// box-sizing: border-box;
                .modal-button {
                        position: fixed;
                        top: 35px;
                        right: 40px;
                        background: none;
                        font-size: .875rem;
                        color: $orange;
                        font-weight: bold;
                        border: none;
                        -webkit-appearance: none;
                        text-transform: uppercase;
                        text-shadow: 0 1px 2px rgba(0,0,0,.7);

                        &.left {
                            right: unset;
                            left: 40px;
                        }
                }
		h4 {
			font-size: 2rem;
			color:#fff;
			margin-top: 0;
			margin-bottom:.75em;

                + button {
                        position: fixed;
                        top: 35px;
                        right: 40px;
                        background: none;
                        font-size: .875rem;
                        color: $orange;
                        font-weight: bold;
                        border: none;
                        -webkit-appearance: none;
                        text-transform: uppercase;
                        text-shadow: 0 1px 2px rgba(0,0,0,.7);

                        &.left {
                            right: unset;
                            left: 40px;
                        }
                }
		}
		h5 {
			font-size: 1.5rem;
			color: #fff;
			margin: .5em 0 .75em 0;
		}
		.modal-body {
			display: flex;
			flex-direction: column;
			height: 100%;
			// position: relative;
			// width: 100%;
			
			.modal-header {
				flex: 0 1 auto;
				padding-top: 1rem;
				h4 {
					font-size: 1.5rem;
				}
			}
			.modal-content {
				flex: 1 1 auto;
				overflow: auto;
				padding-bottom: 6rem;
				// position: relative;
				&.has-double-pane {
					padding-bottom: 0;
					overflow:hidden;
				}
			}
			.action-stuck-bottom {
				position: fixed;
				bottom: 0;
				right: 0;
				left: 0;
				padding:  .5rem 1rem;
				background: rgba(37, 37, 37, 0.9);
				text-align: center;
				backdrop-filter: blur(5px);
				  -webkit-backdrop-filter: blur(5px);
			}
		}
	}
	
}
