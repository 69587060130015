/* Hide scrollbar for Chrome, Safari and Opera */
.crew-drawer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.crew-drawer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.crew-drawer {
  position: fixed;
  left: 10px;
  top: 84px;
  width: 250px;
  z-index: 9999;
  background: #252525c7 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 99px #0000004d;
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  max-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.35s ease;
  will-change: transform;
  .drawer-head {
    flex: 0;
    &.disconnected {
      background-color: red;
    }
    .heading {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      .head-title {
        color: white;
        font-weight: 700;
        font-size: 1rem;
        flex: 1 1 auto;
        padding-bottom: 2px;
      }
      .head-action {
        flex: 1 1 auto;
        font-size: 0.8125rem;
        text-align: right;
      }
      .crew-toggle {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
      }
    }
  }
  .filters {
    .btn-group.sort .btn {
      border-radius: 0;
      background: none;
      box-shadow: none;
      border: none;
      font-weight: normal;
      font-size: 0.8125rem;
      border: 1px solid #525252;
      text-shadow: none;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
        border-left: none;
      }
      &.active {
        background: #525252;
      }
    }
  }
  .equipment-list {
    color: #fff;
    display: block;
    overflow-y: auto;
    .equipment {
      &-data {
        padding: 8px 12px 8px 8px;
        display: flex;
        align-items: center;
      }
      &-icon {
        flex: 0 0 auto;
        text-align: center;
        width: 32px;
        font-size: 16px;
      }
      &-detail {
        flex: 1 1 auto;
        font-size: 1.0625rem;
        padding-left: 8px;
        padding-right: 12px;
        min-width: 0;
        .name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          min-width: 0;
        }
        .status {
          font-size: 0.875rem;
        }
      }
      &-status {
        flex: 0;
        text-align: center;
        font-weight: bold;
        line-height: 0.8;
        padding-right: 3px;
        .speed {
          font-size: 1.0625em;
          &-label {
            font-size: 0.6875em;
          }
        }
      }
      &-meter {
        height: 5px;
        background: #525252;
        &-line {
          height: 100%;
        }
      }
      &-inactive {
        &:not(:last-child) {
          border-bottom: 2px solid #525252;
        }
        .equipment-detail {
          font-size: 0.8125rem;
          padding-left: 4px;
        }
        .equipment-status .inactive {
          font-size: 0.625rem;
        }
      }
    }
  }
}

.bigger {
  .crew-drawer {
    .filters .btn-group.sort .btn {
      font-weight: bold;
      font-size: 0.875rem;
    }
    .equipment-list {
      .equipment {
        &-detail {
          font-size: 1.125rem;
          .status {
            font-size: 1rem;
          }
        }
        &-status {
          font-size: 1.125rem;
        }
        &-inactive {
          .equipment-detail {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.biggest {
  .crew-drawer {
    .filters .btn-group.sort .btn {
      font-weight: bold;
      font-size: 1rem;
    }
    .equipment-list {
      .equipment {
        &-detail {
          font-size: 1.25rem;
          .status {
            font-size: 1.125rem;
          }
        }
        &-status {
          font-size: 1.25rem;
        }
        &-inactive {
          .equipment-detail {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.assign-to-crew {
  padding: 15px;
}
