// input[type="text"],
// select {
//   width: 100%;
//   padding: 12px 20px;
//   margin: 8px 0;
//   display: inline-block;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   box-sizing: border-box;
// }

input[type="text"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="color"],
input[type="email"],
select {
  // width: 100%;
  // padding: 12px 20px;
  // margin: 8px 0;
  // display: block;
  // border: none;
  // border-radius: 8px;
  box-sizing: border-box;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.3);

  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.filter {
    border-radius: 1000px;
    background: rgba(118, 118, 128, 0.44);
    padding-left: 1.5rem;
    color: #fff;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-style: italic;
    }
  }
}

.formik-label {
  margin-bottom: 0px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.formik-error {
  color: red;
}

.btn.btn-primary {
  background: $orange;
  font-size: 1.25rem;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  padding: 1rem 2rem;
  margin: 1rem 0;
}

.btn.btn-secondary {
  background: $light-grey;
  font-size: 1.25rem;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  padding: 1rem 2rem;
  margin: 1rem 0;
}

.check-as-button {
  width: 100%;
  display: flex;
  align-items: stretch;
  input {
    display: none;
  }
  label {
    padding: 0.875em;
    background: rgba(118, 118, 128, 0.24);
    text-align: center;
    border-radius: 12px;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input:checked + label {
    box-shadow: inset 0 0 0 6px $green;
  }
}

.div-as-button {
  display: block;
  padding: 0.875em;
  background: rgba(118, 118, 128, 0.24);
  text-align: center;
  border-radius: 12px;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-align: left;
  box-sizing: border-box;

  &.full-width {
    width: 100% !important;
  }
  &.selected {
    box-shadow: inset 0 0 0 6px $green;
  }
}
